html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 13px;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  width: 100%;
  overflow-x: hidden;
  overscroll-behavior: none;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Signika Negative', sans-serif;
}

div {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

img,
div,
input {
  box-sizing: border-box;
}

#root {
  width: 100%;
}
